<template>
  <div class="md-table-content">
    <md-table
      class="globe-activity Reports responsive"
      :md-sort.sync="listInput.sortText"
      :md-sort-order.sync="listInput.sortOrder"
      :value="
        list.pages[listInput.page] && list.pages[listInput.page].items
          ? list.pages[listInput.page].items
          : []
      "
    >
      <!-- md-fixed-header -->
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell
          md-label="Date"
          md-sort-by="date"
          @click.native="gotoViewer(item)"
          >{{ convertUTCDateToLocalDate(new Date(item.date)) }}
        </md-table-cell>

        <md-table-cell
          md-label="Name"
          md-sort-by="name"
          @click.native="gotoViewer(item)"
        >
          {{ item.firstName }} {{ item.lastName }}
        </md-table-cell>
        <md-table-cell
          md-label="Email"
          md-sort-by="email"
          @click.native="gotoViewer(item)"
        >
          {{ item.email }}
        </md-table-cell>
        <md-table-cell
          md-label="Reader"
          md-sort-by="reader"
          @click.native="gotoViewer(item)"
        >
          {{ item.createdby }}
        </md-table-cell>
        <md-table-cell
          md-label="Device"
          md-sort-by="device"
          @click.native="gotoViewer(item)"
          >{{ item.deviceID }}
        </md-table-cell>
        <md-table-cell md-label="Type" @click.native="gotoViewer(item)">{{
          item.rptType
        }}</md-table-cell>
        <md-table-cell
          md-label="Organization"
          @click.native="gotoViewer(item)"
          >{{ getOrgName(item.orgID) }}</md-table-cell
        >
        <HasPermission :permission="Permissions.ScreeningRead">
          <md-table-cell md-label="Edit">
            <md-icon
              class="edit-icon"
              @click.native="handletheeditClick(item.screeningID)"
              >edit</md-icon
            >
          </md-table-cell>
          <md-table-cell md-label="Action">
            <md-button
              class="md-green-outline"
              style="width: 100%"
              @click.native="handleResendReport(item)"
              >Resend</md-button
            >
          </md-table-cell>
        </HasPermission>
        <md-table-cell class="hidecell"></md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { Error } from "../../../models/SwalMessages";

export default {
  inject: [
    "listInput",
    "list",
    "confirmation",
    "refreshList",
    "showSuccess",
    "Permissions",
    "handleResendReport",
    "handleUpdateReportStatus",
    "app",
    "convertUTCDateToLocalDate",
  ],
  name: "ReportsTable",

  data() {},
  methods: {
    gotoViewer(item) {
      // this.$store.state.screeningeval.ReferralDoctors = [];
      this.$router.push(this.$route.path + "/ReportViewer/" + item.screeningID);
      if (item.status == "unread") {
        this.handleUpdateReportStatus(item.screeningID);
      }
    },
    handletheeditClick(item) {
      if (item) this.$router.push(this.$route.path + "/Edit/" + item);
      else {
        let error = new Error();
        error.title = "Screening Not Found";
        error.text = "Sorry! something went wrong .";
        error.confirmButtonClass = "md-button md-info";
        this.showError(error);
      }
    },
    getOrgName(orgId) {
      let org = this.app.userProfile.organisations.find((x) => x.id == orgId);
      return org == null ? "Unknown" : org.name;
    },
    convertUTCDateToLocalDate(date) {
      // let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let timeZone = "America/New_York";
      var tmp = new Date(
        new Date(
          date.getTime() - new Date().getTimezoneOffset() * 60 * 1000
        ).toLocaleString("en-us", { timeZone })
      );
      var date_time = new Date(tmp),
        mnth = ("0" + (date_time.getMonth() + 1)).slice(-2),
        day = ("0" + date_time.getDate()).slice(-2);
      return [mnth, day, date_time.getFullYear()].join("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.Reports.responsive td:nth-of-type(1):before {
  content: "Date";
}
.Reports.responsive td:nth-of-type(2):before {
  content: "Name";
}
.Reports.responsive td:nth-of-type(3):before {
  content: "Email";
}
.Reports.responsive td:nth-of-type(4):before {
  content: "Reader";
}
.Reports.responsive td:nth-of-type(5):before {
  content: "Device";
}
.Reports.responsive td:nth-of-type(6):before {
  content: "Type";
}
.Reports.responsive td:nth-of-type(7):before {
  content: "Organization";
}
.Reports.responsive td:nth-of-type(8):before {
  content: "Edit";
}
.Reports.responsive td:nth-of-type(9):before {
  content: "Action";
}

.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.md-table-cell {
  //padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
  cursor: pointer;
}
.md-table-row:hover {
  background: #d4d5e6;
}

.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.delete-icon {
  cursor: pointer;
}
</style>
