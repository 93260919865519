<template>
  <div align="Left">
    <list-layout>
      <template v-slot:content>
        <ReportsTable />
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "../../components/layouts/list-layout/ListLayout.vue";
import ReportsTable from "@/components/Globechek/Tables/ReportsTable.vue";
import { withReportGenHandler } from "../../handlers/module-handlers/reports/reportgenHandler";

export default {
  components: { ReportsTable: withReportGenHandler(ReportsTable), ListLayout },
  inject: ["confirmation", "listInput", "list"],
};
</script>

<style lang="scss" scoped>
.globe-activity {
  height: 30vh;
  overflow: auto;
}
.md-table-cell {
  padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
</style>
